import React, { ReactNode } from "react";
import { Props as RealModalProps } from "react-modal";

import { ModalContextProps, ModalHandlerProps } from "@/contexts/ModalContext/types";

export const useModal = (): ModalContextProps => {
  const [isModalOpened, setIsModalOpened] = React.useState(false);
  const [onConfirmCallback, setOnConfirmCallback] = React.useState(null);
  const [onCancelCallback, setOnCancelCallback] = React.useState(null);
  const [hideCloseButton, setHideCloseButton] = React.useState(false);
  const [notClosable, setNotClosable] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const [modalProps, setModalProps] = React.useState<Omit<RealModalProps, "isOpen">>(undefined);
  const [modalContent, setModalContent] = React.useState<ReactNode>(<React.Fragment />);
  const [modalClassName, setModalClassName] = React.useState<string>(undefined);

  const [modalOverlayClassName, setModalOverlayClassName] = React.useState<string>(undefined);

  const handleModal = (props?: ModalHandlerProps) => {
    setIsModalOpened(
      props?.modalState !== undefined ? props.modalState : (prevOpened) => !prevOpened
    );

    if (props) {
      const {
        content,
        onCancelCallback,
        onConfirmCallback,
        modalClassName,
        modalOverlayClassName,
        hideCloseButton,
        notClosable,
        modalProps,
        isLoading,
      } = props;

      const defaultModalProps = {
        className: `u-modal u-box u-box u-box--small u-text-center ${modalClassName}`,
      };
      setModalProps({ ...defaultModalProps, ...modalProps });

      if (content) setModalContent(content);
      if (onConfirmCallback) setOnConfirmCallback(onConfirmCallback);
      if (onCancelCallback) setOnCancelCallback(() => onCancelCallback);
      if (modalClassName) setModalClassName(modalClassName);
      if (modalOverlayClassName) setModalOverlayClassName(modalOverlayClassName);
      if (isLoading) setIsLoading(isLoading);

      setHideCloseButton(hideCloseButton);
      setNotClosable(notClosable);
    }
  };

  return {
    isModalOpened,
    modalContent,
    onConfirmCallback,
    onCancelCallback,
    modalProps,
    hideCloseButton,
    notClosable,
    modalClassName,
    modalOverlayClassName,
    isLoading,
    setIsLoading,
    handleModal,
    setModalContent,
  };
};
