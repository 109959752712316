import(/* webpackMode: "eager" */ "/home/jenkins/workspace/prod/deploy-frontend/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/home/jenkins/workspace/prod/deploy-frontend/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/home/jenkins/workspace/prod/deploy-frontend/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/home/jenkins/workspace/prod/deploy-frontend/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["Footer"] */ "/home/jenkins/workspace/prod/deploy-frontend/src/components/Footer/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/home/jenkins/workspace/prod/deploy-frontend/src/components/Header/Header.tsx");
