import { QREditorSelectorDataProps } from "@/templates/QrEditorTemplates/qr-selector/types";
import { DYNAMIC_QR, STATIC_QR } from "@/types/qr";

export const getStaticQr = (data: QREditorSelectorDataProps): QREditorSelectorDataProps => {
  const dataWithStaticQrs = {
    ...data,
    previewSelector: {
      ...data.previewSelector,
      items: data.previewSelector.items.filter((item) => STATIC_QR.includes(item.id)),
    },
  };

  return dataWithStaticQrs;
};

export const getDynamicQr = (data: QREditorSelectorDataProps): QREditorSelectorDataProps => {
  const dataWithDynamicQrs = {
    ...data,
    previewSelector: {
      ...data.previewSelector,
      items: data.previewSelector.items.filter((item) => DYNAMIC_QR.includes(item.id)),
    },
  };

  return dataWithDynamicQrs;
};

export const isStaticQr = (type: string): boolean =>
  STATIC_QR.includes(type?.replace(/ /g, "-").toLowerCase());
